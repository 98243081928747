<template>
  <v-form class="forma" ref="offerForm" @submit.prevent="">
    <div class="addtime__title">Add Time</div>
    <div class="addprints__subtitle" v-bind:class="{ hide: !isFirst }">
      You have: {{ getHours }} hours {{ getMinutes }} minutes
    </div>
    <div class="addprints__subtitle" v-bind:class="{ hide: isFirst }">
      You choosed: {{ form.offer_name }} (${{ offer_time }})
    </div>
    <div v-bind:class="{ hide: !isFirst }">
      <AmountOffer
        @choseOffer="choseOffer"
        :isSelectOffer="isSelectOffer"
        :offer_name="form.offer_name"
      />
      <div class="forma__buttons">
        <button @click="goback()">Back</button>
        <button
          @click="gonext()"
          v-bind:class="{ blockedbtn: !form.offer_name }"
        >
          {{ form.offer_name ? "Next" : "Choose Offer" }}
        </button>
      </div>
    </div>
    <div v-bind:class="{ hide: isFirst }">
      <div v-if="this.cardNumber">
        <div class="d-flex flex-column align-center">
          <div class="saved">
            <button
              @click="
                {
                  {
                    wait ? '' : (confirmDialog = true);
                  }
                }
              "
              class="saved__use"
              :class="wait ? 'blockedbtn' : ''"
            >
              {{ wait ? "Wait" : "Use this card " + this.cardNumber }}
            </button>
            <!-- <button
              @click="confirmDeletingCard = true"
              type="submit"
              class="saved__delete"
            >
              Delete saved card
            </button> -->
          </div>
          <button
            @click="formWithNewCardIsShown = !formWithNewCardIsShown"
            class="saved__new"
            type="submit"
          >
            Use new card
          </button>
        </div>
      </div>

      <div v-show="!this.cardNumber || formWithNewCardIsShown">
        <div class="forma__all">
          <div class="forma__savecard">
          <!-- <label class="check">
            <input type="checkbox" v-model="form.is_save_card" />
            <span></span>
            Save Card
          </label> -->
          <div class="forma__savecard__title">
            Credit cards saved automatically to your account after purchase
          </div>
        </div>
          <v-text-field
            v-model="form.name_on_card"
            outlined
            color="black"
            class="forma__input"
            label="Name on Card"
            v-validate="'required'"
            data-vv-name="name card"
            :error-messages="errorsBags.collect('name card')"
          >
          </v-text-field>
          <v-text-field
            v-model="cardNumberWithMask"
            outlined
            color="black"
            class="forma__input"
            label="Card Number"
            v-mask="'#### #### #### ####'"
            v-validate="'required|min:18|max:19'"
            data-vv-name="card number"
            :error-messages="errorsBags.collect('card number')"
            @blur="form.card_number = cardNumberWithMask.replace(/\s+/g, '')"
            append-icon="mdi-credit-card-check-outline"
          >
          </v-text-field>

          <div class="forma__ddmm">
            <v-text-field
              v-model="form.date_mm"
              v-mask="'##'"
              label="Month"
              v-validate="'required|numeric|min:1|max:2'"
              data-vv-name="Month"
              :error-messages="errorsBags.collect('Month')"
              outlined
              color="black"
              class="forma__ddmm__input"
              type="number"
            ></v-text-field>
            <v-text-field
              v-model="form.date_yy"
              v-mask="'##'"
              label="Year"
              v-validate="'required|numeric|year'"
              data-vv-name="Year"
              :error-messages="errorsBags.collect('Year')"
              outlined
              color="black"
              class="forma__ddmm__input"
              type="number"
            ></v-text-field>
            <v-text-field
              v-model="form.cvv"
              v-mask="'####'"
              label="CVV"
              v-validate="'required|numeric|min:3|max:4'"
              data-vv-name="cvv"
              :error-messages="errorsBags.collect('cvv')"
              outlined
              color="black"
              class="forma__ddmm__input"
              type="number"
            ></v-text-field>
          </div>
          <div class="forma__garants">
            <div class="forma__garants__title">
              Guarantee Safe Checkout Powered by CardKnox
            </div>
            <img
              src="../../assets/images/card/visa.png"
              alt=""
              class="forma__garants__img"
            />
            <img
              src="../../assets/images/card/mastercard.png"
              alt=""
              class="forma__garants__img"
            />
            <img
              src="../../assets/images/card/card.png"
              alt=""
              class="forma__garants__img"
            />
            <img
              src="../../assets/images/card/amex.png"
              alt=""
              class="forma__garants__img"
            />
          </div>
        </div>
      </div>
      <v-text-field
          v-model="form.promocode"
          label="Promocode"
          color="black"
      ></v-text-field>
      <v-dialog max-width="500px" v-model="confirmDialog">
        <ConfirmPay
          @closeConfirmDialog="closeConfirmDialog"
          @confirmPay="buyOffer(true)"
        />
      </v-dialog>
      <v-dialog max-width="500px" v-model="confirmDeletingCard">
        <ConfirmDeletingCard
          @closeConfirmDeletingCard="closeConfirmDeletingCard"
          @deletingSavedCard="deletingSavedCard"
        />
      </v-dialog>
      <v-dialog v-model="payCashDialog" max-width="500px">
        <CashPay
            :price=offer_time
            :custom-fields="cashFields"
            @closeCashPayDialog="closePayCashDialog"
            @confirmPay="confirmPayCash"
        />
      </v-dialog>
      <div class="forma__buttons">
        <button @click="isFirst = !isFirst">Back</button>
        <button
          v-show="!this.cardNumber || formWithNewCardIsShown"
          @click="
            {
              {
                wait ? '' : buyOffer(false);
              }
            }
          "
          :class="wait ? 'blockedbtn' : ''"
        >
          {{ wait ? "Wait" : "Add time" }}
        </button>
        <button v-show="cashAvailable" class="full-width-button" @click="openPayCashDialog()">Pay by cash</button>

      </div>
    </div>
  </v-form>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import ConfirmPay from "../common/modal/ConfirmPay";
import ConfirmDeletingCard from "../common/modal/ConfirmDeletingCard";
import AmountOffer from "../common/AmountOffer";
import CashPay from "../common/modal/CashPay";

export default {
  name: "AddTimeForm",
  props: {
    isSignUp: {
      type: Boolean,
      default: true,
    },
  },
  components: { ConfirmPay, ConfirmDeletingCard, AmountOffer, CashPay },
  data: () => ({
    formWithNewCardIsShown: false,
    isFirst: true,
    offer_time: "",
    form: {
      name_on_card: "",
      card_number: "",
      date_mm: "",
      date_yy: "",
      cvv: "",
      offer_name: "",
      offer_id: null,
      use_saved_card: "",
      is_save_card: true,
      promocode: null,
    },
    userInfo: {
      balance: 0,
    },
    cardNumberWithMask: "",
    confirmDialog: false,
    isSelectOffer: true,
    confirmDeletingCard: false,
    wait: false,
    payCashDialog: false,
    cashAvailable : localStorage.cashAvailable === 'true',
  }),
    mounted() {
        this.$validator.extend('year', {
            validate: (value) => {
                const valueString = String(value);
                return valueString.length === 2 || valueString.length === 4;
            },
            getMessage(field){
                return ` The ${field} field must contain exactly 2 or 4 digits.`;
            }
        });
    },
  computed: {
    ...mapState(["cardNumber", "time", "offers", "userId"]),
    getHours() {
      return Math.floor(this.time / 60);
    },
    getMinutes() {
      return Math.round((this.time / 60 - this.getHours) * 60).toLocaleString(
        "en-US",
        {
          minimumIntegerDigits: 2,
        }
      );
    },
    years() {
      const years = [];
      const date = new Date();
      const rangeDate = 13;
      let startYear = date.getFullYear();
      let endYear = date.getFullYear() + rangeDate;
      while (startYear <= endYear) {
        years.push(startYear);
        startYear++;
      }

      return years;
    },

    cashFields() {
      return {
        type: "offers",
        user_id: this.userId.toString(),
        offer_id: this.form?.offer_id?.toString(),
        offer_name: this.form?.offer_name,
        originalPrice: this.offer_time.toString(),
        promocode: this.form?.promocode
      };
    },
  },
  created() {
    this.fetchOffers();
  },
  methods: {
    ...mapActions(["setNotification", "setCardNumber", "fetchUserInfo"]),
    ...mapMutations(["updateOffers"]),

    openPayCashDialog() {
      this.payCashDialog = true;
    },
    async confirmPayCash() {
      this.setNotification({
        show: true,
        type: "success",
        message: "your transaction was completed",
      });
      this.$refs.offerForm.reset();
      this.formWithNewCardIsShown = false;
      this.wait = false;
      this.$router.push({ name: "dashboard" });
    },
    closePayCashDialog() {
      this.payCashDialog = false;
    },

    async fetchOffers() {
      try {
        const response = await this.$axios.get("/api/sloffers");
        if (response.status === 200) {
          this.updateOffers(response.data);
        }
        this.form.offer_name = null;
      } catch (err) {
        console.error(err);
      }
    },
    goback() {
      this.$router.go(-1);
    },
    gonext() {
      if (this.form.offer_name != null) {
        this.isFirst = !this.isFirst;
      }
    },
    choseOffer(item) {
      if(item !== null) {
        this.isSelectOffer = true;
      this.form.offer_name = item.Name;
      this.form.offer_id = item.offer_id;
      this.offer_time = item.CostPrice;
      } else {
        this.isSelectOffer = false;
        this.form.offer_name = null;
        this.form.offer_id = null;
        this.offer_time = "";
      }
    },
    async buyOffer(isSavedCard) {
      try {
        if (isSavedCard) {
          this.form.use_saved_card = true;
        } else {
          this.form.use_saved_card = false;
        }
        const isValid = !isSavedCard
          ? await this.$validator.validateAll()
          : true;
        if (!this.form.offer_name) {
          this.isSelectOffer = false;
        }
        if (isValid && this.isSelectOffer) {
          this.wait = true;
          const payload = new FormData();
          for (const key in this.form) {
            payload.append(key, this.form[key]);
          }
          const response = await this.$axios.post(
            `/api/users/buyOffer/${this.username}/${this.form.offer_id}`,
            payload
          );
          if (response.status === 200) {
            this.setNotification({
              show: true,
              type: "success",
              message: response.data.message,
            });
            this.$eventBus.$emit("updateUser", response.data.user);
            this.setCardNumber({
              cardNumber: response.data.user.cardNumber,
            });
          }
          this.wait = false;
          this.$refs.offerForm.reset();
          this.formWithNewCardIsShown = false;
          this.$router.push({ name: "dashboard" });
        }
      } catch (error) {
        console.error(error);
        this.wait = false;
      }
    },
    async deletingSavedCard() {
      try {
        const response = await this.$axios.get(`/api/users/remove_credit_card`);
        if (response.status === 200) {
          this.setCardNumber({
            cardNumber: "",
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    closeConfirmDialog() {
      this.confirmDialog = false;
    },
    closeConfirmDeletingCard() {
      this.confirmDeletingCard = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.hide {
  display: none;
}
.blockedbtn {
  background-color: #7a7a7a;
  border: none;
}
</style>
