<template>
  <div class="addtime__btns__div">
    <div class="addtime__btns">
      <button v-for="(offer, index) in offers" :key="index" style="position: relative;" class="addtime__btns__choose"
        @click="showOffers(offer.data, offer.name)" :class="`${offerGroupName === offer.name ? 'focus' : ''} `">
        <div style="height: 15px" v-if="offer.isPromo"></div>
        {{ offer.name }}
        <img style="position: absolute; top: -7px; right: 0" width="55px" v-if="offer.isPromo"
          src="../../../public/new_offer.svg" />
      </button>
    </div>
    <div class="addtime__friday" >
    {{
      offers.find((item) => item.name === offerGroupName)?.description
      }}
    </div>
    <div class="addtime__btns">
      <button v-for="(item, index) in offersData" :key="index" class="addtime__btns__time"
        :class="`${item.Name === offer_name ? 'active' : ''} `" @click="choseOffer(item)" ref="btn">
        {{ item.Name }} (${{ item.CostPrice }})
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"

export default {
  name: "AmountOffer",
  props: {
    offer_name: {
      type: String,
    },
    isSelectOffer: {
      type: Boolean,
    }
  },
  data: () => ({
    offersData: "",
    offerGroupName: "",
  }),
  computed: {
    ...mapState(["offers"])
  },
  watch: {
    offers(newVal) {
      if (newVal && newVal.length) {
        this.preselectOffer()
      }
    }
  },
  mounted() {
    if (this.offers && this.offers.length) {
      this.preselectOffer()
    }
  },

  methods: {
    preselectOffer() {
      const preselectGroup = this.$route.query.preselect

      const group = this.offers.filter((offer) => offer.name === preselectGroup)

      if (group && group.length) {
        this.showOffers(group[0].data, group[0].name)
      } else {
        this.showOffers(this.offers[0].data, this.offers[0].name)
      }
    },
    showOffers(data, name) {
      this.offerGroupName = name
      this.offersData = data
      this.$emit("choseOffer", null)
    },
    choseOffer(item) {
      this.$emit("choseOffer", item)
    },
  },
}
</script>

<style lang="scss" scoped>
@import "src/styles/variables";

.active {
  background: none;
  color: black;
}

.focus {
  background: #d5de20;
  color: #000 !important;
  border: 2px solid #000;
}
</style>
